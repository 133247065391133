@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700);
body {
  font-family: "Source Sans Pro", sans-serif;
  background: #000000 url(/static/media/queens_tower_phone.68037131.jpg) no-repeat fixed
    center top;
  background-size: cover;
}

.speaker {
  height: 30px;
  width: 30px;
  bottom: 10px;
  left: 10px;
  position: absolute;
  overflow: hidden;
  display: inline-block;
}
.speaker span {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  margin: 11px 0 0 2px;
}
.speaker span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  border-width: 10px 14px 10px 15px;
  left: -13px;
  top: 5px;
}
.speaker span:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 0 50px 0 0;
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-style: double;
  border-color: #fff;
  border-width: 7px 7px 0 0;
  left: 18px;
  top: 9px;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.speaker:hover span:before {
  -webkit-transform: scale(0.8) translate(-3px, 0) rotate(42deg);
          transform: scale(0.8) translate(-3px, 0) rotate(42deg);
}
.speaker.mute span:before {
  -webkit-transform: scale(0.5) translate(-15px, 0) rotate(36deg);
          transform: scale(0.5) translate(-15px, 0) rotate(36deg);
  opacity: 0;
}

a:hover {
  text-decoration: none;
}

.round-corners {
  border-radius: 5%;
}

.question {
  background: #f5f5f5 url(/static/media/speech-bubble.b9b240f8.svg) no-repeat right top;
  background-size: 100px;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #333 !important;
}

.triangles {
  background-image: url("/images/icons/background_squares.jpg");
  background-position: center top;
  background-size: 100%;
}

.card-title,
.card-text,
.card-text > p {
  color: black;
  text-align: center;
}

.card-title {
  font-size: 1.8rem;
}

.card-text > p {
  margin: 1.1rem 0;
  font-size: 1.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: white;
}

p {
  font-size: 1.6rem;
  margin: 2.2rem 0;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
p > a {
  color: inherit;
  text-decoration: underline;
}

.logo-shadow {
  /* Use -webkit- only if supporting: Chrome < 54, iOS < 9.3, Android < 4.4.4 */
  -webkit-filter: drop-shadow(0 0 10px #000);
  filter: drop-shadow(0 0 10px #000);
}

#down-arrow {
  height: 7rem;
}

#down-arrow:hover {
  cursor: pointer;
}

#jumbotron {
  margin-top: 0vh;
  background-color: transparent;
}

#page-top-wrapper {
  display: flex;
  height: 100vh;
}

#page-top-content {
  flex-grow: 1;
}

div.section {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0rem;
  padding-bottom: 5rem;
}

.background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

#ic-hack-logo-container {
  width: 270px;
  margin: 0 auto;
}

#main-event-details {
  margin-top: 30px;
  color: white;
  text-align: center;
}

#main-event-details > p {
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.15;
  text-shadow: 0 0 10px black;
}

.events::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 2px;
  top: -8px;
  background: #333;
  left: 24.5%;
  z-index: 0;
}

.white_triangles {
  background-image: url("/images/icons/wsquare.png");
  background-position: center;
  background-size: cover;
}

@media (max-width: 600px) {
  body {
    font-family: "Source Sans Pro", sans-serif;
    background: #000000 url(/static/media/queens_tower_phone.68037131.jpg) no-repeat right top
      fixed;
    background-size: 350%;
  }
  .speaker {
    display: none;
  }
  .background-video {
    display: none;
  }
  .triangles {
    background-image: url("/images/icons/background_squares.jpg");
    background-position: center top;
    background-size: 300%;
  }
}

#jumbotron {
  margin-top: 10vh;
}

@media (min-height: 650px) {
  #jumbotron {
    margin-top: 10vh;
  }
}

@media (min-width: 480px) {
  .hideLarge {
    display: none;
  }
  #ic-hack-logo-container {
    width: 380px;
  }

  #main-event-details > p {
    font-size: 2em;
  }

  #jumbotron {
    margin-top: 15vh;
  }

  .events::after {
    left: 24.5%;
  }
}

@media (min-width: 768px) {
  #ic-hack-logo-container {
    width: 500px;
  }

  #main-event-details > p {
    font-size: 2.2em;
  }

  #jumbotron {
    margin-top: 15vh;
  }

  .events::after {
    left: 70px;
  }
}

@media (min-width: 992px) {
  #ic-hack-logo-container {
    width: 650px;
  }

  #main-event-details > p {
    font-size: 2.5em;
  }

  #jumbotron {
    margin-top: 20vh;
  }

  .events::after {
    left: 97px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .events::after {
    left: 124px;
  }
}

